import img1 from './assets/images/BNB.png';
import img2 from './assets/images/BNBWHITE.png';
import img3 from './assets/images/BNB_TINY.svg';
import img4 from './assets/images/BNB_TINY_BLACK.svg';
import img5 from './assets/images/CUSTOM_DOLLOR.png';
import img6 from './assets/images/CUSTOM_DOLLOR_TINY.png';
import img7 from './assets/images/CUSTOM_DOLLOR_TINY.svg';
import img8 from './assets/images/CUSTOM_DOLLOR_TINY_BLACK.png';
import img9 from './assets/images/CUSTOM_DOLLOR_TINY_GREY.png';
import img10 from './assets/images/CUSTOM_DOLLOR_TINY_ORANGE.png';
import img11 from './assets/images/CUSTOM_DOLLOR_TINY_green.png';
import img12 from './assets/images/CleanShot 2021-11-28 at 19.59 4.gif.mp4';
import img13 from './assets/images/Dollor2.png';
import img14 from './assets/images/Dollor3.png';
import img15 from './assets/images/Dollor4.png';
import img16 from './assets/images/EVEN_BG-HOVER.png';
import img17 from './assets/images/EVEN_BG.png';
import img18 from './assets/images/GreenTag.png';
import img19 from './assets/images/Hamburgur.png';
import img20 from './assets/images/LANGUAGES.png';
import img21 from './assets/images/LOGO_DESKTOP.png';
import img22 from './assets/images/LOGO_MOBILE.png';
import img23 from './assets/images/MARBLES.png';
import img24 from './assets/images/METAMASK.png';
import img25 from './assets/images/MUSIC.png';
import img26 from './assets/images/ODD_BG-HOVER.png';
import img27 from './assets/images/ODD_BG.png';
import img28 from './assets/images/RedTag.png';
import img29 from './assets/images/STAR.png';
import img30 from './assets/images/STAR_TINY.png';
import img31 from './assets/images/STAR_TINY.svg';
import img32 from './assets/images/STAR_TINY_BLACK.svg';
import img33 from './assets/images/STAR_TINY_GREY.svg';
import img34 from './assets/images/USER.png';
import img35 from './assets/images/WalletConnector.svg';
import img36 from './assets/images/coin_l.png';
import img37 from './assets/images/coin_r.png';
import img38 from './assets/images/dollor.svg';
import img39 from './assets/images/game-start-modal-bg.png';
import img40 from './assets/images/game-start-modal-marble-min.png';
import img41 from './assets/images/game-start-modal-marble.png';
import img42 from './assets/images/game-start-modal-mobile.png';
import img43 from './assets/images/game-start-modal.png';
import img44 from './assets/images/golden-bnb.png';
import img45 from './assets/images/hero-bg-mobile.png';
import img46 from './assets/images/hero-bg.png';
import img47 from './assets/images/leader-head-bg-mobile.png';
import img48 from './assets/images/leader-head-bg.png';
import img49 from './assets/images/loss-overlay.png';
import img50 from './assets/images/loss.gif';
import img51 from './assets/images/lost-bnb.png';
import img52 from './assets/images/lost-modal-bg.jpg';
import img53 from './assets/images/lost-modal.png';
import img54 from './assets/images/lost-star.png';
import img55 from './assets/images/m_logo.png';
import img56 from './assets/images/marble-popup-bnb-img.jpg';
import img57 from './assets/images/marble-popup-leaderboard-img.jpg';
import img58 from './assets/images/marble-popup-sqm-img.jpg';
import img59 from './assets/images/marble-popup.jpg';
import img60 from './assets/images/marbles-help.png';
import img61 from './assets/images/no-marble-modal-bg.png';
import img62 from './assets/images/no-marble-modal.png';
import img63 from './assets/images/no-marbles-overlay.png';
import img64 from './assets/images/no-marbles2.gif';
import img65 from './assets/images/preloader-bg.jpg';
import img66 from './assets/images/select-bnb-bg-active.png';
import img67 from './assets/images/select-bnb-bg.png';
import img68 from './assets/images/sm_logo.png';
import img69 from './assets/images/squid-moon-currency-icon.png';
import img70 from './assets/images/squid-moon-logo.svg';
import img71 from './assets/images/star-vector.svg';
import img72 from './assets/images/thunder.svg';
import img73 from './assets/images/wall_login_bg.png';
import img74 from './assets/images/win-dollor.png';
import img75 from './assets/images/win-modal-bg.jpg';
import img76 from './assets/images/win-modal.jpg';
import img77 from './assets/images/win-overlay-gradient.png';
import img78 from './assets/images/win-star.png';
import img79 from './assets/images/win.gif';
import img80 from './assets/images/winner1.png';
import img81 from './assets/images/winner2.png';
import img82 from './assets/images/winner3.png';
import img83 from './assets/images/yellow-thunder.png';

import audio1 from './assets/audios/activate-quick-play.mp3';
import audio2 from './assets/audios/deactivate-quick-play.mp3';
import audio3 from './assets/audios/select-bet-1.mp3';
import audio4 from './assets/audios/select-bet-2.mp3';
import audio5 from './assets/audios/select-bet-3.mp3';
import audio6 from './assets/audios/squid-moon-bg-music.mp3';

let images=[]
images[1] = new Image();
images[1].src = img1;
images[2] = new Image();
images[2].src = img2;
images[3] = new Image();
images[3].src = img3;
images[4] = new Image();
images[4].src = img4;
images[5] = new Image();
images[5].src = img5;
images[6] = new Image();
images[6].src = img6;
images[7] = new Image();
images[7].src = img7;
images[8] = new Image();
images[8].src = img8;
images[9] = new Image();
images[9].src = img9;
images[10] = new Image();
images[10].src = img10;
images[11] = new Image();
images[11].src = img11;
images[12] = new Image();
images[12].src = img12;
images[13] = new Image();
images[13].src = img13;
images[14] = new Image();
images[14].src = img14;
images[15] = new Image();
images[15].src = img15;
images[16] = new Image();
images[16].src = img16;
images[17] = new Image();
images[17].src = img17;
images[18] = new Image();
images[18].src = img18;
images[19] = new Image();
images[19].src = img19;
images[20] = new Image();
images[20].src = img20;
images[21] = new Image();
images[21].src = img21;
images[22] = new Image();
images[22].src = img22;
images[23] = new Image();
images[23].src = img23;
images[24] = new Image();
images[24].src = img24;
images[25] = new Image();
images[25].src = img25;
images[26] = new Image();
images[26].src = img26;
images[27] = new Image();
images[27].src = img27;
images[28] = new Image();
images[28].src = img28;
images[29] = new Image();
images[29].src = img29;
images[30] = new Image();
images[30].src = img30;
images[31] = new Image();
images[31].src = img31;
images[32] = new Image();
images[32].src = img32;
images[33] = new Image();
images[33].src = img33;
images[34] = new Image();
images[34].src = img34;
images[35] = new Image();
images[35].src = img35;
images[36] = new Image();
images[36].src = img36;
images[37] = new Image();
images[37].src = img37;
images[38] = new Image();
images[38].src = img38;
images[39] = new Image();
images[39].src = img39;
images[40] = new Image();
images[40].src = img40;
images[41] = new Image();
images[41].src = img41;
images[42] = new Image();
images[42].src = img42;
images[43] = new Image();
images[43].src = img43;
images[44] = new Image();
images[44].src = img44;
images[45] = new Image();
images[45].src = img45;
images[46] = new Image();
images[46].src = img46;
images[47] = new Image();
images[47].src = img47;
images[48] = new Image();
images[48].src = img48;
images[49] = new Image();
images[49].src = img49;
images[50] = new Image();
images[50].src = img50;
images[51] = new Image();
images[51].src = img51;
images[52] = new Image();
images[52].src = img52;
images[53] = new Image();
images[53].src = img53;
images[54] = new Image();
images[54].src = img54;
images[55] = new Image();
images[55].src = img55;
images[56] = new Image();
images[56].src = img56;
images[57] = new Image();
images[57].src = img57;
images[58] = new Image();
images[58].src = img58;
images[59] = new Image();
images[59].src = img59;
images[60] = new Image();
images[60].src = img60;
images[61] = new Image();
images[61].src = img61;
images[62] = new Image();
images[62].src = img62;
images[63] = new Image();
images[63].src = img63;
images[64] = new Image();
images[64].src = img64;
images[65] = new Image();
images[65].src = img65;
images[66] = new Image();
images[66].src = img66;
images[67] = new Image();
images[67].src = img67;
images[68] = new Image();
images[68].src = img68;
images[69] = new Image();
images[69].src = img69;
images[70] = new Image();
images[70].src = img70;
images[71] = new Image();
images[71].src = img71;
images[72] = new Image();
images[72].src = img72;
images[73] = new Image();
images[73].src = img73;
images[74] = new Image();
images[74].src = img74;
images[75] = new Image();
images[75].src = img75;
images[76] = new Image();
images[76].src = img76;
images[77] = new Image();
images[77].src = img77;
images[78] = new Image();
images[78].src = img78;
images[79] = new Image();
images[79].src = img79;
images[80] = new Image();
images[80].src = img80;
images[81] = new Image();
images[81].src = img81;
images[82] = new Image();
images[82].src = img82;
images[83] = new Image();
images[83].src = img83;
let audios=[]
audios[1] = new Audio();
audios[1].src = audio1;
audios[2] = new Audio();
audios[2].src = audio2;
audios[3] = new Audio();
audios[3].src = audio3;
audios[4] = new Audio();
audios[4].src = audio4;
audios[5] = new Audio();
audios[5].src = audio5;
audios[6] = new Audio();
audios[6].src = audio6;
