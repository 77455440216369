import { ethers } from 'ethers';
import { action, makeObservable, observable } from "mobx";
import Moralis from "moralis";
import sqmAbi from "../assets/abi/sqm.json";
import { GAME_ADDRESS, SQM_ADDRESS } from '../utils/helpers';

const PRIZE_BOOST = 50000;

export class ChainStore {

  provider = {};
  sqmContract = {};
  prices = {
    bnb: 0,
    sqm: 0,
  };
  payout = 0;
  prize = 0;
  player = {};
  players = [];
  leaderBoard = [];
  top8players = [];
  top8PlayersScore = 0;

  constructor() {
    makeObservable(this, {
      //observables
      prices: observable,
      payout: observable,
      player: observable,
      players: observable,
      leaderBoard: observable,

      //actions
      getPrize: action,
      setPlayer: action,
      setPlayers: action,
    });
    this.setPrices();
    this.setProvider();
  }

  setProvider = () => {
    if(window.ethereum) {
      this.provider = new ethers.providers.Web3Provider(window.ethereum)
      this.sqmContract = new ethers.Contract(SQM_ADDRESS, sqmAbi, this.provider)
    }
  }

  setPlayer = (player) => {
    this.player = player;
  }

  setPlayers = (players) => {
    this.leaderBoard = players.slice(0, 50);
    this.top8players = players.slice(0, Math.round(players.length * 0.08))
    this.top8PlayersScore = this.top8players.reduce((prev, curr) => parseFloat(prev) + parseFloat(ethers.utils.formatEther(curr.score)), 0)
    this.players = players;
  }

  setPrices = async () => {
    const serverUrl = "https://jm28bhhhurnq.usemoralis.com:2053/server"; // TODO: set as env variable
    const appId = "ubK27Qp3w9MAC7XtxPK0SNrKwRi1fYFPbgq0ccKL"; // TODO: set as env variable

    Moralis.start({ serverUrl, appId });

    const BNB_ADDRESS = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" // TODO: set as env variable
    const SQM_ADDRESS = "0x2766cc2537538ac68816b6b5a393fa978a4a8931" // TODO: set as env variable

    this.prices = {
      bnb: await getPriceFromPancakeSwapV2(BNB_ADDRESS),
      sqm: await getPriceFromPancakeSwapV2(SQM_ADDRESS)
    }
  }

  getPrize = async () => {
    let _balance = 0
    try {
      _balance = await this.sqmContract.balanceOf(GAME_ADDRESS);
    } catch (e) {
      console.log('failed to getPrize', e)
    }
    
    this.prize = (PRIZE_BOOST + (this.prices.sqm * parseFloat(ethers.utils.formatEther(_balance))) * 0.9).toFixed(0);
  }

  getPayout = (player) => {
    let payout = 0;

    this.top8players.forEach((p) => {
      if (p.id.toLowerCase() === player.id.toLowerCase()){
        payout = (parseFloat(this.prize) * (parseFloat(ethers.utils.formatEther(p.score)) / this.top8PlayersScore)).toFixed(2);
      }
    });

    return payout;
  }
}

var getPriceFromPancakeSwapV2 = async (address) => {
  const options = {
    address,
    chain: "bsc",
    exchange: "PancakeSwapv2"
  }

  let response
  try {
    response = await Moralis.Cloud.run("getTokenPrice", options)
  } catch(e) {
    console.log(`failed to fetch price for contract '${address}'`, e)
    return 0
  }

  return (response.usdPrice && response.usdPrice.toFixed(2)) || 0
}

export default ChainStore;
