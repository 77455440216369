import { ethers } from 'ethers';
import gameAbi from "../assets/abi/game.json";
import { GAME_ADDRESS } from './helpers';
import { BET_RESULTS } from "../config/constants/game";
import { BET_RESULTS_STRING } from "../config/constants/game";

export const listenForBetResults = async (provider, _betId) => {
    if(provider == null) {
        return [];
    }

    let squidMoonGame = new ethers.Contract(GAME_ADDRESS, gameAbi, provider);
    const filters = squidMoonGame.filters.BetResolved(_betId, null);
    const squidMinted = squidMoonGame.filters.SquidMinted(_betId, null);
    const squidBurned = squidMoonGame.filters.SquidBurned(_betId, null);
    const liquidityAdded = squidMoonGame.filters.LiquidityAdded(_betId, null, null);

    return new Promise((resolve, reject) => {
        // todo: should put this in a try and have reject implemented

        let betResolved = false;
        let sqmValueReceived = false;
        const results = {
            betId: '',
            result: "PENDING",
            sqmWon: 0,
            sqmBurned: 0,
            sqmLiqAdded: 0,
            usdLiqAdded: 0
        }

        squidMoonGame.on(filters, (betId, result) => {
            results.betId = betId
            results.result = BET_RESULTS_STRING[result]

            betResolved = true;
            if (sqmValueReceived || result === BET_RESULTS.REIMBURSED){
                squidMoonGame.removeAllListeners();
                resolve(results);
            }
        });

        // ======== Result WIN
        squidMoonGame.on(squidMinted, (betId, sqmWon) => {
            results.sqmWon = sqmWon

            sqmValueReceived = true;
            if (betResolved){
                squidMoonGame.removeAllListeners();
                resolve(results);
            }
        });

        // ======== Result Lost
        squidMoonGame.on(squidBurned, (betId, sqmBurned) => {
            results.sqmBurned = sqmBurned

            sqmValueReceived = true;
            if (betResolved){
                squidMoonGame.removeAllListeners();
                resolve(results);
            }
        });

        // ======== Result Draw/None
        squidMoonGame.on(liquidityAdded, (betId, sqmLiqAdded, usdLiqAdded) => {
            results.sqmLiqAdded = sqmLiqAdded
            results.usdLiqAdded = usdLiqAdded

            sqmValueReceived = true;
            if (betResolved){
                squidMoonGame.removeAllListeners();
                resolve(results);
            }
        });
    });
}
