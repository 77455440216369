import React from "react";
// import Header from "../header";
// import Footer from "../footer";

const Index = ({ children }) => {
  return (
    <div className="">
      {/* <Header /> */}
      <div className="">{children}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default Index;
