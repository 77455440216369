import React, { useEffect, useRef } from "react";
import "./style.css";

export const DotsAnimation = () => {
  const canvasRef = useRef(null);

  const drawAnimation = () => {
    // configuration
    const dotMargin = 119, 
      numRows = 3, 
      numCols = 3,
      color = "#e7e8f1",
      directions = ["-", "+"],
      speeds = [0.03, 0.05, 0.07],
      canvasWidth = 600,
      canvasHeight = 600;

    let canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    // const canvasWidth = canvas.width;
    // const canvasHeight = canvas.height; // this one is new
    canvas.height = canvasHeight;
		canvas.width = canvasWidth;

    // const dotWidth = (canvasWidth - 2 * dotMargin) / numCols - dotMargin;
    // const dotHeight = (canvasHeight - 2 * dotMargin) / numRows - dotMargin;
    const dotWidth = 1;
    const dotHeight = 2;

    let xMargin, yMargin, dotDiameter, i, j, dotRadius, dots;
    if (dotWidth > dotHeight) {
      dotDiameter = dotHeight;
      xMargin = (canvasWidth - (2 * dotMargin + numCols * dotDiameter)) / numCols;
      yMargin = dotMargin;
    } else {
      dotDiameter = dotWidth;
      xMargin = dotMargin;
      yMargin = (canvasHeight - (2 * dotMargin + numRows * dotDiameter)) / numRows;
    }

    // Start with an empty array of dots.
    dots = [];
    dotRadius = dotDiameter * 0.5;

    for (i = 0; i < numRows; i++) {
      for (j = 0; j < numCols; j++) {
        const x =
          j * (dotDiameter + xMargin) + dotMargin + xMargin / 2 + dotRadius;
        const y =
          i * (dotDiameter + yMargin) + dotMargin + yMargin / 2 + dotRadius + (j % 2 ? Math.floor(Math.random() * 100) : -Math.floor(Math.random() * 100));
        // Get random color, direction and speed.
        const xMove = directions[Math.floor(Math.random() * directions.length)];
        const yMove = "-";
        const speed = speeds[Math.floor(Math.random() * speeds.length)];
        // Set the object.
        const dot = {
          x: x,
          y: y,
          radius: dotRadius,
          xMove: xMove,
          yMove: yMove,
          color: color,
          speed: speed,
        };
        // Save it to the dots array.
        dots.push(dot);
        drawDot(dot);
      }
    }

    // Draw each dot in the dots array.
    for (i = 0; i < dots.length; i++) {
      drawDot(dots[i]);
    }

    window.requestAnimationFrame(moveDot);

    function moveDot() {
      context.clearRect(0, 0, canvasWidth, canvasHeight);

      for (i = 0; i < dots.length; i++) {
        if (dots[i].xMove === "+") {
          dots[i].x += dots[i].speed;
        } else {
          dots[i].x -= dots[i].speed;
        }
        if (dots[i].yMove === "+") {
          dots[i].y += dots[i].speed;
        } else {
          dots[i].y -= dots[i].speed;
        }

        drawDot(dots[i]);

        if (dots[i].x + dots[i].radius >= canvasWidth) {
          dots[i].x = dots[i].radius;
        }
        if (dots[i].x - dots[i].radius <= 0) {
          dots[i].x = canvasWidth - dots[i].radius;
        }
        if (dots[i].y + dots[i].radius >= canvasHeight) {
          dots[i].y = dots[i].radius;
        }
        if (dots[i].y - dots[i].radius <= 0) {
          dots[i].y = canvasHeight - dots[i].radius;
        }
      }

      window.requestAnimationFrame(moveDot);
    }

    function drawDot(dot) {
      context.globalAlpha = 0.9;
      context.beginPath();
      context.arc(dot.x, dot.y, dot.radius, 0, 2 * Math.PI, false);
      context.fillStyle = dot.color;
      context.fill();
    }
  };

  useEffect(() => {
    drawAnimation();
  }, []);

  return (
    <canvas className="dots" ref={canvasRef}>
      Your browser does not support canvas.
    </canvas>
  );
};
