import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useQuery } from "@apollo/client";
import { QUERY_MY_BETS } from "../../utils/queries";
import { useLastBets, setLastBets } from "./store";

const useMyBets = (query_count = 10) => {
  const lastBets = useLastBets();
  const { account } = useWeb3React();
  const { loading: betsQueryLoading, data: betsFromQuery } = useQuery(
    QUERY_MY_BETS,
    { variables: { player: account.toLowerCase(), first: query_count } }
  );

  const bets = useMemo(() => {
    
    const merged = lastBets.reduce(
      (result, bet) => {
        const duplicatedIndex = result.findIndex((ele) => {
          return ele.id === bet.id;
        });
        if (!~duplicatedIndex) {
          return [bet, ...result];
        }
        const duplicated = result[duplicatedIndex];
        if (bet.result !== duplicated.result) {
          result[duplicatedIndex] =
            bet.result < duplicated.result ? { ...duplicated } : { ...bet };
        }
        return result;
      },
      !betsQueryLoading ? betsFromQuery.bets : []
    );

    return merged;
  }, [lastBets, betsFromQuery, betsQueryLoading]);

  const addLastBet = (bet) => {
    setLastBets([...lastBets, bet]);
  };

  return {
    addLastBet,
    bets,
    betsQueryLoading,
  };
};

export default useMyBets;
