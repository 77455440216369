/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect } from "react";
import { ethers } from 'ethers';
import { TinyStar } from "../../assets/svg";
import BnbWhite from "../../assets/images/BNBWHITE.png";
import "./style.css";
import { useTranslation } from "react-i18next";
import useMyBets from "../../hooks/bet/useMyBets";

/*const data = [
  {
    bet: "Odd",
    bnb: 1,
    pending: true,
    result: [{ win: false }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
  {
    bet: "Even",
    bnb: 2,
    pending: false,
    result: [{ win: true }, { lost: false }],
    star: "+23",
    dollor: "+512",
  },
];*/

const PrevGame = () => {
  const { t } = useTranslation();
  const { bets: data } = useMyBets(10)
  const scrollContainer = useRef();
  if (scrollContainer.current) {
    scrollContainer.current.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.current.scrollLeft += evt.deltaY;
    });
  }

  return (
    <section className="prev-game">
      {data.length !== 0 && <div className="container relative">
        <h2 className="font-bold text-2xl"> {t("sections.prevGame.yourGames")}</h2>
        <div
          className="flex items-center mt-6 overflow-auto game-card-container"
          ref={scrollContainer}
        >
          {data.map((v, i) => (
            <div
              key={i}
              className={`prev-game-card rounded-2xl mr-8 flex-shrink-0 ${v.result === v.bet ? "win" : ""
                }`}
            >
              <div className="pb-2 pt-2 px-4 pr-24 bg-dark-700 rounded-t-2xl ">
                <h4 className="font-bold text-3xl">{t("sections.prevGame.bet")} {v.bet}</h4>
                <div className="flex items-center">
                  <img src={BnbWhite} alt="" />
                  <p className="text-base font-bold mx-1">{parseFloat(ethers.utils.formatEther(v.amount))} BNB</p>
                </div>
              </div>
              {v.result === 'PENDING' ? (
                <div className="prev-game-card-btm pb-2 pt-2 px-4 w-full">
                  <p className="font-bold text-base">{t("sections.prevGame.result")}</p>
                  <div className="flex items items-center text-xs mt-1">
                    <i className="far fa-clock"></i>
                    <p className="ml-1">{t("sections.prevGame.pending")}</p>
                  </div>
                </div>
              ) : (
                <div
                  className={`prev-game-card-btm pb-2 pt-2 px-4 w-full flex justify-between `}
                >
                  <div style={{ paddingRight: 10 }}>
                    <p className="font-bold text-base">{t("sections.prevGame.result")}</p>
                    <div className="flex items items-center text-xs mt-1">
                      <p className="">{v.result} / {v.result === "NONE" ? t("sections.prevGame.none") : (v.result === v.bet ? t("sections.prevGame.win") : t("sections.prevGame.lost"))}</p>
                    </div>
                  </div>
                  <div className="flex items-end text-base prev-game-card-btm-right" >
                    <div className="flex items-center">
                      {(v.result === "EVEN" || v.result === "ODD") &&
                        <TinyStar />
                      }
                      {v.result !== v.bet && (v.result === "EVEN" || v.result === "ODD") &&
                        <p className="text-xs ml-1">- {parseFloat(ethers.utils.formatEther(v.amount))} BNB</p>
                      }
                    </div>
                    <div className="flex items-center ml-2">
                      {v.result === v.bet &&
                          <p className="text-xs ml-1">+ {parseFloat(ethers.utils.formatEther(v.result === v.bet ? v.sqmWon : v.sqmBurned)).toFixed(1)} SQM</p>
                        }
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>}
    </section>
  );
};

export default PrevGame;
