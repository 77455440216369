/* eslint-disable no-unused-vars */
import { Link } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { ethers } from 'ethers';
import React, { useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import Web3 from 'web3';
import Big from 'big.js';
import gameAbi from "../../assets/abi/game.json";
import QuickPlayActivate from "../../assets/audios/activate-quick-play.mp3";
import QuickPlayDeactivate from "../../assets/audios/deactivate-quick-play.mp3";
import SelectBnb1 from "../../assets/audios/select-bet-1.mp3";
import SelectBnb2 from "../../assets/audios/select-bet-2.mp3";
import SelectBnb3 from "../../assets/audios/select-bet-3.mp3";
import TinyBnbImg from "../../assets/images/BNB_TINY.svg";
import TinyBnbImgBlack from "../../assets/images/BNB_TINY_BLACK.svg";
import TinyDoller2Grey from "../../assets/images/CUSTOM_DOLLOR_TINY_GREY.png";
import TinyDoller2Orange from "../../assets/images/CUSTOM_DOLLOR_TINY_ORANGE.png";
import EvenBgHover from "../../assets/images/EVEN_BG-HOVER.png";
import EvenBg from "../../assets/images/EVEN_BG.png";
import Marbles from "../../assets/images/MARBLES.png";
import OddBgHover from "../../assets/images/ODD_BG-HOVER.png";
import OddBg from "../../assets/images/ODD_BG.png";
import TinyStarImg from "../../assets/images/STAR_TINY.svg";
import TinyStarImgGrey from "../../assets/images/STAR_TINY_GREY.svg";
import { BET_RESULTS, BET_RESULTS_STRING } from "../../config/constants/game";
import { Header } from "../../components";
import GameStartModal from "../../components/modals/GameStartModal";
import LostModal from "../../components/modals/LostModal";
import MarbleModal from "../../components/modals/MarbleModal";
import NoMarbleModal from "../../components/modals/NoMarbleModal";
import WinModal from "../../components/modals/WinModal";
import { useStores } from "../../stores/RootStore";
import { 
  GAME_ADDRESS, 
  BEFORE_BET,
  START_BET,
  PENDING_BET,
  CONFIRM_BET,
  RESOLVE_BET,
  useIsWalletConnected } from '../../utils/helpers';
import { listenForBetResults } from '../../utils/bets';
import "./style.css";
import useMyBets from "../../hooks/bet/useMyBets";

const bnbValues = [0.035, 0.1, 0.25, 0.5, 1];
const decimals = 18;

const HeroSection = ({ checkAuth }) => {
  const { t } = useTranslation();
  const { chainStore } = useStores();
  const { getPrize, prices, prize } = chainStore;

  const { account, library, chainId } = useWeb3React();
  const isWalletConnected = useIsWalletConnected();
  const [currentActive, setCurrentActive] = useState();
  const [activeGame, setActiveGame] = useState(false);
  const [winGame, setWinGame] = useState(false);
  const [lossGame, setLossGame] = useState(false);
  const [noMarbleGame, setNoMarbleGame] = useState(false);
  const [marbleGame, setMarbleGame] = useState(false);
  const [active, setActive] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isError, setShowError] = useState(false)
  const [bet, setBet] = useState(0)
  const [betOutcome, setBetOutcome] = useState(0)
  const [sqmWon, setSqmWon] = useState(0)
  const {addLastBet} = useMyBets();
  const [bnb, setBnb] = useState(0);
  const [processing, setProcessing] = useState(BEFORE_BET);
  const [error, setError] = useState(false);
  const [currentBet, setCurrentBet] = useState({});
  const betInput = useMemo(() => { return { betId: "", account: "", bet: "" } }, []);
  const data = bnbValues.map(value => ({
    value,
    star: `+${value}`,
    dollor: `+$${(prices.bnb * value).toFixed(0)} SQM`,
  }));
  const [modalData, setModalData] = useState({
    score: data[0].value,
    betInBNB: data[0].value,
    betInUSD: data[0].value * prices.bnb
  })

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const { Interface } = ethers.utils;

  const quickPlayActivate = useRef(null);
  const quickPlayDeactivate = useRef(null);
  const bet1 = useRef(null);
  const bet2 = useRef(null);
  const bet3 = useRef(null);

  const gameHandler = () => {
    setActiveGame((prev) => !prev);
  };
  const winHandler = () => {
    setWinGame((prev) => !prev);
  };
  const lossHandler = () => {
    setLossGame((prev) => !prev);
  };
  const noMarbleHandler = () => {
    setNoMarbleGame((prev) => !prev);
  };
  const marbleHandler = () => {
    setMarbleGame((prev) => !prev);
  };

  const activeHandler = (i, v) => {
    if (currentActive === i) {
      return
    }

    const betButtonAudio = i === 0 ? bet1 : i === 1 ? bet2 : bet3
    betButtonAudio.current.currentTime = 0;
    betButtonAudio.current.play();

    setModalData({
      score: v,
      betInBNB: v,
      betInUSD: prices.bnb * v
    })

    setBnb(v);
    setCurrentActive(i);
    setShowError(false)
  };

  const getAccountBalanceBNB = async () => {
    const _balance = await library.eth.getBalance(account)
    setBalance(Web3.utils.fromWei(_balance.toString(), "ether"))
  }

  const waitForResultOnBet = async (betId) => {
    setProcessing(BEFORE_BET)
    let results = await listenForBetResults(provider, betId);
    let betOutcome = results.result
    setBetOutcome(betOutcome);

    if (betOutcome === BET_RESULTS_STRING[betInput.bet]) {
      setSqmWon(results.sqmWon)
      setWinGame(true);
    } else if (betOutcome === BET_RESULTS_STRING[BET_RESULTS.PENDING]) {
      // todo: when does this happen?
    } else if (betOutcome === BET_RESULTS_STRING[BET_RESULTS.NONE]) {
      setNoMarbleGame(true);
    } else {
      setLossGame(true);
    }

    setActiveGame(false);

    const newBet = {id: betId, bet: BET_RESULTS_STRING[betInput.bet], amount: Big(10).pow(decimals).mul(bnb).toString(), ...results};
    addLastBet(newBet);
  }



  useEffect(() => {
    async function fetchData() {
      activeHandler(0, data[0].value)
      if (isWalletConnected) {
        getAccountBalanceBNB();
        getPrize();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, isWalletConnected])

  // etherjs
  const etherHandler = async (value) => {
    try {
      if (parseFloat(bnb) > parseFloat(balance)) {
        setShowError(true)
        return
      }

      setBet(value);
      setProcessing(START_BET);

      let signer = provider.getSigner();
      let contract = new ethers.Contract(GAME_ADDRESS, gameAbi, signer)
      let SqInterface = new Interface(gameAbi);
      let bnbValue = ethers.utils.parseEther(bnb.toString());
      let txHash = await contract.placeBet(value, { value: bnbValue })

      setProcessing(PENDING_BET)

      const receipt = await txHash.wait();

      setProcessing(CONFIRM_BET)
      setActiveGame(true);

      let betPlacedLog = SqInterface.parseLog(receipt.logs[3]).args;
      betInput.betId = betPlacedLog.betId;
      betInput.account = betPlacedLog.player;
      betInput.bet = betPlacedLog.bet;

      setCurrentBet({betId: betPlacedLog.betId, account: betPlacedLog.account, bet: betPlacedLog.bet});

      // wait for BetResolved
      waitForResultOnBet(betInput.betId);
    } catch (error) {
      setActiveGame(false)
      setError(true)
      setProcessing(BEFORE_BET)
      console.log('error message', error)
    }
  }

  const quickPlayHandler = () => {
    const quickPlaySound = active ? quickPlayDeactivate : quickPlayActivate
    quickPlaySound.current.currentTime = 0;
    quickPlaySound.current.play();
    setActive((prev) => !prev);
  };

  return (
    <>
      <section className="bg-dark-500 pb-14 hero-section relative cus_mobile_adjust">
        <Header checkAuth={checkAuth} />
        {/*       <section className="bg-dark-500  min-h-screen hero-section relative">
         <Header checkAuth={checkAuth}/>*/}
        <div className="container">
          <div className="mx-auto hidden lg:flex items-center justify-center w-full my-10 md:my-4">
            <div
              className=" cursor-pointer marble-popup-parent relative"
              onClick={marbleHandler}
            >
              <div className="marbles-question">
                <i className="fas fa-question"></i>
              </div>
              <img src={Marbles} alt="" />
            </div>
            <p className="font-medium text-2xl ml-3"> {t("sections.hero.marbles")}</p>
          </div>
          {/* <div>
            <h1 className="font-mineCraft text-4xl mx-auto text-center hidden lg:block text-yellow my-8">
              <span className="minecraft-dollor">S</span>{" "}
              <CountUp end={prize} duration={2} separator=',' /> {t("sections.hero.prize")}
            </h1>
          </div> */}

          {processing === BEFORE_BET && !error && !isError && <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-14 relative">
            <div
              className={(isWalletConnected) ? 'even' : 'even disabled'}
              role="button"
              onClick={() => {
                if (isWalletConnected) {
                  etherHandler(BET_RESULTS.EVEN)
                }
              }
              }
              data-aos="fade-up"
            >
              {isWalletConnected && <>
                <img src={EvenBg} alt="" className="w-full even-bg" />
                <img src={EvenBgHover} alt="" className="w-full even-hover" />
              </>}
              <p>{t("sections.hero.even")}</p>
            </div>
            <div
              className={(isWalletConnected) ? 'odd' : 'odd disabled'}
              role="button"
              onClick={() => {
                if (isWalletConnected) {
                  etherHandler(BET_RESULTS.ODD)
                }
              }
              }
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {isWalletConnected && <>
                <img src={OddBg} alt="" className="w-full odd-bg" />
                <img src={OddBgHover} alt="" className="w-full odd-hover" />
              </>}
              <p>{t("sections.hero.odd")}</p>
              <div className="tooltips"><span className="text-danger">{t("sections.hero.betAmount")}</span></div>
            </div>
            <div className="or hidden md:flex">
              <p className="font-bold text-xl">{t("sections.hero.or")}</p>
            </div>
          </div>
          }
          {isError && <div className="pending_approve">
            <h2 className="mb-4 text-2xl fw-bold ">
              {t("sections.hero.notEnough")}
            </h2>
          </div>}
          {processing !== BEFORE_BET  &&
            <div className="pending_approve">
              <div className="mb-2 d-flex justify-content-center text-yellow">
                <div className="loader"></div>{t("sections.hero.pending")}
              </div>
              {processing === START_BET && 
                <h2 className="mb-4 text-2xl fw-bold ">
                  {t("sections.hero.approve")} <br />{t("sections.hero.throughWallet")}
                </h2>
              }
              {processing === PENDING_BET && 
                <h2 className="mb-4 text-2xl fw-bold ">
                  {t("sections.hero.waiting")}
                </h2>
              }
              {processing === CONFIRM_BET && 
                <h2 className="mb-4 text-2xl fw-bold ">
                  {t("sections.hero.confirm")}
                </h2>
              }
              <p>{t("sections.hero.description")} <Link>{t("sections.hero.here")}</Link></p>
            </div>
          }


          {error &&
            <div className="pending_approve error_sec">
              <div className="mb-2 text-danger">
                {t("sections.hero.failed")}
              </div>
              <h2 className="mb-4 text-2xl fw-bold ">
                {t("sections.hero.notApproved")}
              </h2>
              <span className="close_btn cursor-pointer" onClick={() => setError(false)}>{t("sections.hero.close")}</span>
            </div>
          }

          <div className="mt-16">
            <p className="text-gray-400 font-bold mb-2">{t("sections.hero.bnbAmount")}</p>
            <div className="flex flex-wrap justify-center  lg:justify-between gap-4 lg:my-0 my-8">
              {data.map((v, i) => (
                <div
                  className={`flex bg-dark-300 py-2 rounded-md cursor-pointer select-bnb ${currentActive === i ? "active" : ""
                    }`}
                  key={i}
                  onClick={() => activeHandler(i, v.value)}
                >
                  <div
                    className={"flex flex-col justify-center relative italic select-bnb-left"}
                  >
                    <p className="ml-4 font-medium text-xl text-nowrap">
                      {v.value}
                    </p>
                    <p className="ml-4 font-medium text-xs text-nowrap">
                      BNB
                      <img
                        src={currentActive === i ? TinyBnbImgBlack : TinyBnbImg}
                        className="w-3 ml-1 inline"
                        alt=""
                      />
                    </p>
                    <div className="tick">
                      <i className="fas fa-check"></i>
                    </div>
                  </div>
                  <div className="px-2 flex flex-col justify-around py-1 select-bnb-right">
                    <div className="md:flex items-center hidden">
                      <img
                        src={
                          currentActive === i ? TinyStarImg : TinyStarImgGrey
                        }
                        alt=""
                      />
                      <p className="text-sm ml-2 font-bold">{v.star}</p>
                    </div>
                    <div className="flex items-center dollor -ml-4 md:-ml-9">
                      <img
                        src={
                          currentActive === i ? TinyDoller2Orange : TinyDoller2Grey
                        }
                        alt=""
                        className="hidden md:block "
                      />
                      <p className="text-sm md:ml-2 font-bold text-nowrap">{!prices.bnb ? '...' : v.dollor}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div
              onClick={quickPlayHandler}
              className={`border ${active ? " border-yellow" : " border-white "
                } flex items-center p-4 lg:py-7 lg:px-6 rounded-xl cursor-pointer justify-center transition-all`}
            >
              {active ? <img src={YellowThunder} alt="" /> : <Thunder />}{" "}
              <p className={`${active ? "text-yellow" : ""}  select-none ml-2`}>
                {t("sections.hero.quickPlay")}
              </p>
            </div> */}
          </div>
        </div>
        <audio ref={quickPlayActivate} src={QuickPlayActivate}></audio>
        <audio ref={quickPlayDeactivate} src={QuickPlayDeactivate}></audio>
        <audio ref={bet1} src={SelectBnb1}></audio>
        <audio ref={bet2} src={SelectBnb2}></audio>
        <audio ref={bet3} src={SelectBnb3}></audio>
      </section>
      <GameStartModal
        activeGame={activeGame}
        setActiveGame={setActiveGame}
        gameHandler={gameHandler}
        currentBet={currentBet}
      />
      <WinModal
        activeGame={winGame}
        setActiveGame={setWinGame}
        gameHandler={winHandler}
        modalData={modalData}
        bet={bet}
        betOutcome={betOutcome}
        sqmWon={sqmWon}
      />
      <LostModal
        activeGame={lossGame}
        setActiveGame={setLossGame}
        gameHandler={lossHandler}
        modalData={modalData}
        bet={bet}
        betOutcome={betOutcome}
        />
      <NoMarbleModal
        activeGame={noMarbleGame}
        setActiveGame={setNoMarbleGame}
        gameHandler={noMarbleHandler}
        modalData={modalData}
        bet={bet}
        betOutcome={betOutcome}
      />
      <MarbleModal
        activeGame={marbleGame}
        setActiveGame={setMarbleGame}
        gameHandler={marbleHandler}
      />
    </>
  );
};

export default HeroSection;
