import { useWeb3React } from "@web3-react/core";

export const useIsWalletConnected = () => {
    const { chainId , account } = useWeb3React();
    return account && chainId.toString() === process.env.REACT_APP_CHAIN_ID
}

export const useIsIncorrectNetwork = () => {
    const { chainId , account } = useWeb3React();
    return account && chainId.toString() !== process.env.REACT_APP_CHAIN_ID
}

export const GAME_ADDRESS = "0x687759a811858a5ae4Deb1929e0b6Ce0E5DC137e"
export const SQM_ADDRESS = "0x2766CC2537538aC68816B6B5a393fA978A4a8931"

export const BEFORE_BET = 'BEFORE_BET'
export const START_BET = 'START_BET'
export const PENDING_BET = 'PENDING_BET'
export const CONFIRM_BET = 'CONFIRM_BET'
export const RESOLVE_BET = 'RESOLVE_BET'

export const parseToUsdPriceFormat = (str) => str === null ? '' : str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
