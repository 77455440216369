import React, { useEffect } from "react";
import useSound from 'use-sound';
import { Layout } from "../components";
import HeroSection from "../sections/HeroSection";
import PrevGameSection from "../sections/PrevGameSection";
// import LeaderBoard from "../sections/LeaderBoard";
import Auth from "./../components/auth";
import "./style.css";
import { useIsWalletConnected } from '../utils/helpers';
import backgroundMusic from '../assets/audios/squid-moon-bg-music.mp3';
import { DotsAnimation } from "../components/dotsAnimation";

const Home = () => {
  const isWalletConnected = useIsWalletConnected();
  const [play, music] = useSound(backgroundMusic, { loop: true });

  useEffect(() => {
    if (isWalletConnected) {
      //play();
    } else {
      //music.stop();
    }
  }, [isWalletConnected, music, play])

  if (!isWalletConnected) {
    return  <>
      <Auth />
      <DotsAnimation />
    </>
    
  } else {
    return (
      <div
        // data-aos="fade-out"
        // data-aos-delay="200"
        className="bg-dark-700"
      >
        <Layout>
          <HeroSection />
          <div className="py-20 bg-dark-700 bottom-section">
            <PrevGameSection />
            {/* <LeaderBoard /> */}
          </div>
        </Layout>
        <DotsAnimation />
      </div>
    );
  }
};

export default Home;
