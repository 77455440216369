import create from "zustand";

const store = create(() => ({
  lastBets: [],
}));

const useLastBets = () => {
  return store().lastBets;
};

const setLastBets = (lastBets) => {
  store.setState({ lastBets });
};

export { useLastBets, setLastBets };
