import { useTranslation } from "react-i18next";
import WinDollor from "../../assets/images/win-dollor.png";
import GifOverlay from "../../assets/images/win-overlay-gradient.png";
import WinStar from "../../assets/images/win-star.png";
import { ethers } from 'ethers';
import Gif from "../../assets/images/win.gif";
import { BET_RESULTS } from "../../config/constants/game";
import "./style.css";
const GameWinModal = ({ activeGame, setActiveGame, gameHandler, modalData, bet, betOutcome }) => {
  const { t } = useTranslation();
  return (
    <div className={`modal win-modal ${activeGame ? "flex" : "hidden"} `}>
      <div className="modal-content flex justify-center items-end text-center pb-20 relative">
        <div className="close-modal" onClick={gameHandler}>
          <i className="fas fa-times"></i>
        </div>
        <img src={Gif} className="gif" alt="" />
        <img src={GifOverlay} className="gif-overlay" alt="" />
        <div className="win-modal-btm">
          <p className="font-bold text-xl md:text-3xl mb-6">{t("modals.win.bet")} {Object.keys(BET_RESULTS)[bet]}</p>
          <h2 className="font-bold mb-6 sm:text-2xl  md:text-5xl lg:text-7xl">
            {t("modals.win.result")} {Object.keys(BET_RESULTS)[betOutcome]}
          </h2>
          <div className="flex items-center justify-center md:mt-4 score">
            <div className="flex items-center justify-end mr-4 ">
              <img src={WinStar} alt="" />
              <p className="ml-2 font-bold">+{modalData.score}</p>
            </div>
            <div className="flex items-center">
              <img src={WinDollor} alt="" />
              <p className="ml-2 font-bold">+{parseFloat(ethers.utils.formatEther(modalData.sqmWon || 0)).toFixed(1)} SQM</p>
            </div>
          </div>
        </div>
      </div>
      <p className="mt-10 text-lg text-white">{t("modals.win.congrats")}</p>
    </div>
  );
};

export default GameWinModal;
